<template>
    <div>
        <v-alert
            type="error"
            class="mb-5"
            :value="errorMessage">
            {{ errorMessage }}
        </v-alert>

        <div class="mb-5">
            <h2>Google Vehicle Listing Ads (VLA) Setup</h2>
            <p>
                To setup Google VLAs follow the steps in the guide below and check the
                confirmation box to confirm that you've completed the necessary setup steps!
            </p>
        </div>

        <div class="flex mx-auto justify-center">
            <a
                href="https://support.buyerbridge.com/knowledge/how-to-setup-google-vehicle-listing-ads-vlas-in-google-ads-manager"
                target="_blank"
                class="vla-guide">
                <div class="mx-3 my-2">
                    <div class="flex justify-center">
                        <div>
                            <icon
                                class="icon"
                                width="35"
                                height="35"
                                :color="colors.blue"
                                name="book" />
                        </div>
                        <div class="ml-2">
                            READ THE GOOGLE VLA SETUP GUIDE
                        </div>
                    </div>
                </div>
            </a>
        </div>

        <div class="mt-2 mb-5 flex mx-auto justify-center">
            <div>
                <div class="flex mt-4">
                    <div>
                        <styled-checkbox
                            :value="true"
                            :checked="checkBox"
                            square
                            @change="handleCheckBoxChange" />
                    </div>
                    <div class="mb-1 ml-2">
                        Click here to confirm once you have completed the setup guide
                    </div>
                </div>
            </div>
        </div>

        <div class="layout align-center">
            <back-button @click="goToPreviousStep" />
            <styled-button
                class="ml-auto"
                :disabled="!checkBox"
                :loading="loading"
                @click="handleContinue">
                Continue
            </styled-button>
        </div>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import BackButton from '@/components/globals/BackButton';
import colors from '@/helpers/colors.js';
import Icon from '@/components/globals/Icon';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'GoogleBusinessProfile',
    components: {
        StyledButton,
        StyledCheckbox,
        BackButton,
        Icon
    },
    data() {
        return {
            loading: false,
            checkBox: null,
            errorMessage: '',
            colors
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters([
            'dealerFeaturesComplete'
        ]),
    },
    methods: {
        ...mapActions([
            'goToPreviousStep',
            'goToNextStep'
        ]),
        async handleContinue() {
            try {
                this.errorMessage = '';
                this.loading = true;

                const config = {
                    google: {
                        vehicle_listings_setup: true
                    }
                };

                await this.$apiRepository.updateDealerConfiguration(this.dealer.id, config);

                this.goToNextStep();
            } catch(error) {
                this.errorMessage = error;
            } finally {
                this.loading = false;
            }
        },
        handleCheckBoxChange(event) {
            this.checkBox = event.target.checked;
        }
    }
};
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
    align-items: center;
}

.vla-guide {
    border-radius: 18px;
    border: 1px solid rgba(151,151,151, 1);
    opacity: 1;
    background-color: rgba(250,246,246, 1);
    text-decoration: none;
    color: inherit;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.w-1\/2 {
    width: 50%;
}

.w-full {
    width: 100%;
}

.justify-center {
    justify-content: center;
}
</style>